
export default {
  apiGateway: {
      REGION: "us-west-2",
      URL: "https://1ki41gprpe.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_x2xtx11bD",
      APP_CLIENT_ID: "5fbkmpa7m8k1o9ga7vuj20kubt",
      IDENTITY_POOL_ID: "us-west-2:ccfd07e2-5319-43da-b8ab-ebfb9de03ab1"
  }
}