const result = [
   {
      "vciStatus":{"d":{"i":1}, "a": {"a": 1}},
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":1,
            "alleleNumber":8734,
            "alleleFrequency":0.000114495,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1622,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3494,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15008,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13856,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17124,
            "alleleFrequency":5.83976e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30980,
            "alleleFrequency":3.22789e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA607426712",
      "grch38HGVS":"NC_000012.12:g.102838329A>G",
      "grch37HGVS":"NC_000012.11:g.103232107A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*846T>C",
         "ENST00000553106.5:c.*846T>C",
         "NM_000277.1:c.*846T>C",
         "XM_011538422.1:c.*846T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000114495
         }
      ]
   },
   {
      "vciStatus":{"d":{"i":1}},
      "inSilicoPredictionScoreStatements":[
         {
           "algorithm":"REVEL",
           "prediction":0.755
         },
         {
           "algorithm":"CADD",
           "prediction":6.768786
         },
         {
           "algorithm":"M-CAP",
           "prediction":0.615724670278
         },
         {
           "algorithm":"S-CAP exonic",
           "prediction":0.014369234867749022
        },
        {
           "algorithm":"S-CAP 3 prime intronic",
           "prediction":0.0022005359511053026
        }
        // more types for S-CAP
     ],   
      "caid":"CA682818981",
      "grch38HGVS":"NC_000012.12:g.102838335_102838337del",
      "grch37HGVS":"NC_000012.11:g.103232113_103232115del",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*839_*841del",
         "ENST00000553106.5:c.*839_*841del",
         "NM_000277.1:c.*839_*841del",
         "XM_011538422.1:c.*839_*841del"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8732,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1622,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3490,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":1,
            "alleleNumber":15012,
            "alleleFrequency":6.66134e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":1,
            "alleleNumber":13858,
            "alleleFrequency":7.21605e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":0,
            "alleleNumber":17120,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30978,
            "alleleFrequency":3.2281e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA607426714",
      "grch38HGVS":"NC_000012.12:g.102838335A>G",
      "grch37HGVS":"NC_000012.11:g.103232113A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*840T>C",
         "ENST00000553106.5:c.*840T>C",
         "NM_000277.1:c.*840T>C",
         "XM_011538422.1:c.*840T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":6.66134e-05
         }
      ]
   },
   {
      "vciStatus":{"d":{"i":2,"p":1}},
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA655173654",
      "grch38HGVS":"NC_000012.12:g.102838359A>G",
      "grch37HGVS":"NC_000012.11:g.103232137A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*816T>C",
         "ENST00000553106.5:c.*816T>C",
         "NM_000277.1:c.*816T>C",
         "XM_011538422.1:c.*816T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "vciStatus":{"d":{"i":1}},
      "caid":"CA682818989",
      "grch38HGVS":"NC_000012.12:g.102838376C>A",
      "grch37HGVS":"NC_000012.11:g.103232154C>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*799G>T",
         "ENST00000553106.5:c.*799G>T",
         "NM_000277.1:c.*799G>T",
         "XM_011538422.1:c.*799G>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8728,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":2,
            "alleleNumber":1616,
            "alleleFrequency":0.00123762,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3488,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":14994,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":1,
            "alleleNumber":13844,
            "alleleFrequency":7.22335e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17104,
            "alleleFrequency":5.84659e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":2,
            "alleleNumber":30948,
            "alleleFrequency":6.46245e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742934",
      "grch38HGVS":"NC_000012.12:g.102838378G>T",
      "grch37HGVS":"NC_000012.11:g.103232156G>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*797C>A",
         "ENST00000553106.5:c.*797C>A",
         "NM_000277.1:c.*797C>A",
         "XM_011538422.1:c.*797C>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00123762
         }
      ]
   },
   {
      "vciStatus":{"d":{"i":2,"p":1}},
      "caid":"CA682818992",
      "grch38HGVS":"NC_000012.12:g.102838380T>C",
      "grch37HGVS":"NC_000012.11:g.103232158T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*795A>G",
         "ENST00000553106.5:c.*795A>G",
         "NM_000277.1:c.*795A>G",
         "XM_011538422.1:c.*795A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "vciStatus":{"a":{"a":1}},
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8734,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":1,
            "alleleNumber":838,
            "alleleFrequency":0.00119332,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3494,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15006,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":1,
            "alleleNumber":13854,
            "alleleFrequency":7.21813e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":0,
            "alleleNumber":17120,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30974,
            "alleleFrequency":3.22851e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "caid":"CA607426717",
      "grch38HGVS":"NC_000012.12:g.102838383_102838384del",
      "grch37HGVS":"NC_000012.11:g.103232161_103232162del",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*791_*792del",
         "ENST00000553106.5:c.*791_*792del",
         "NM_000277.1:c.*791_*792del",
         "XM_011538422.1:c.*791_*792del"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00119332
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742936",
      "grch38HGVS":"NC_000012.12:g.102838384C>T",
      "grch37HGVS":"NC_000012.11:g.103232162C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*791G>A",
         "ENST00000553106.5:c.*791G>A",
         "NM_000277.1:c.*791G>A",
         "XM_011538422.1:c.*791G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "vciStatus":{"a":{"p":1}},
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8732,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1620,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":4,
            "alleleNumber":15006,
            "alleleFrequency":0.00026656,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":1,
            "alleleNumber":982,
            "alleleFrequency":0.00101833,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":1,
            "alleleNumber":13858,
            "alleleFrequency":7.21605e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":4,
            "alleleNumber":17114,
            "alleleFrequency":0.000233727,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":5,
            "alleleNumber":30972,
            "alleleFrequency":0.000161436,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742939",
      "grch38HGVS":"NC_000012.12:g.102838391A>G",
      "grch37HGVS":"NC_000012.11:g.103232169A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*784T>C",
         "ENST00000553106.5:c.*784T>C",
         "NM_000277.1:c.*784T>C",
         "XM_011538422.1:c.*784T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00026656
         }
      ]
   },
   {
      "caid":"CA682819009",
      "grch38HGVS":"NC_000012.12:g.102838393A>C",
      "grch37HGVS":"NC_000012.11:g.103232171A>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*782T>G",
         "ENST00000553106.5:c.*782T>G",
         "NM_000277.1:c.*782T>G",
         "XM_011538422.1:c.*782T>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8724,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":836,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1620,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3484,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":1,
            "alleleNumber":14998,
            "alleleFrequency":6.66756e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13844,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17100,
            "alleleFrequency":5.84795e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30944,
            "alleleFrequency":3.23164e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742940",
      "grch38HGVS":"NC_000012.12:g.102838396G>A",
      "grch37HGVS":"NC_000012.11:g.103232174G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*779C>T",
         "ENST00000553106.5:c.*779C>T",
         "NM_000277.1:c.*779C>T",
         "XM_011538422.1:c.*779C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":6.66756e-05
         }
      ]
   },
   {
      "caid":"CA682819011",
      "grch38HGVS":"NC_000012.12:g.102838396G>T",
      "grch37HGVS":"NC_000012.11:g.103232174G>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*779C>A",
         "ENST00000553106.5:c.*779C>A",
         "NM_000277.1:c.*779C>A",
         "XM_011538422.1:c.*779C>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":153,
            "alleleNumber":8726,
            "alleleFrequency":0.0175338,
            "homozygousAlleleIndividualCount":1
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":1,
            "alleleNumber":838,
            "alleleFrequency":0.00119332,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3490,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":6,
            "alleleNumber":15008,
            "alleleFrequency":0.000399787,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":2,
            "alleleNumber":982,
            "alleleFrequency":0.00203666,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":72,
            "alleleNumber":13850,
            "alleleFrequency":0.00519856,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":90,
            "alleleNumber":17114,
            "alleleFrequency":0.00525885,
            "homozygousAlleleIndividualCount":1
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":162,
            "alleleNumber":30964,
            "alleleFrequency":0.00523188,
            "homozygousAlleleIndividualCount":1
         }
      ],
      "clinVarSubmission":{
         "reviewStatus":"criteria provided, single submitter",
         "name":"NM_000277.2(PAH):c.*772_*775delGTAA",
         "clinVarRCVs":[
            {
               "clinVarRCV":"RCV000333803",
               "significance":"Uncertain significance"
            }
         ],
         "clinVarSCVs":[
            {
               "clinVarSCV":"SCV000375555.2",
               "evaluationDate":"Jun 14, 2016",
               "significance":"Uncertain significance"
            }
         ],
         "variation":306909,
         "clinicalSignificance": "Uncertain significance"
      },
      "caid":"CA10636257",
      "grch38HGVS":"NC_000012.12:g.102838403_102838406del",
      "grch37HGVS":"NC_000012.11:g.103232181_103232184del",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*772_*775del",
         "ENST00000553106.5:c.*772_*775del",
         "NM_000277.1:c.*772_*775del",
         "XM_011538422.1:c.*772_*775del"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.0175338
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA655173655",
      "grch38HGVS":"NC_000012.12:g.102838414dup",
      "grch37HGVS":"NC_000012.11:g.103232192dup",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*761dup",
         "ENST00000553106.5:c.*761dup",
         "NM_000277.1:c.*761dup",
         "XM_011538422.1:c.*761dup"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742943",
      "grch38HGVS":"NC_000012.12:g.102838425C>T",
      "grch37HGVS":"NC_000012.11:g.103232203C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*750G>A",
         "ENST00000553106.5:c.*750G>A",
         "NM_000277.1:c.*750G>A",
         "XM_011538422.1:c.*750G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819022",
      "grch38HGVS":"NC_000012.12:g.102838426C>T",
      "grch37HGVS":"NC_000012.11:g.103232204C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*749G>A",
         "ENST00000553106.5:c.*749G>A",
         "NM_000277.1:c.*749G>A",
         "XM_011538422.1:c.*749G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742945",
      "grch38HGVS":"NC_000012.12:g.102838429A>C",
      "grch37HGVS":"NC_000012.11:g.103232207A>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*746T>G",
         "ENST00000553106.5:c.*746T>G",
         "NM_000277.1:c.*746T>G",
         "XM_011538422.1:c.*746T>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742948",
      "grch38HGVS":"NC_000012.12:g.102838432A>G",
      "grch37HGVS":"NC_000012.11:g.103232210A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*743T>C",
         "ENST00000553106.5:c.*743T>C",
         "NM_000277.1:c.*743T>C",
         "XM_011538422.1:c.*743T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819026",
      "grch38HGVS":"NC_000012.12:g.102838434C>G",
      "grch37HGVS":"NC_000012.11:g.103232212C>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*741G>C",
         "ENST00000553106.5:c.*741G>C",
         "NM_000277.1:c.*741G>C",
         "XM_011538422.1:c.*741G>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819027",
      "grch38HGVS":"NC_000012.12:g.102838441_102838444del",
      "grch37HGVS":"NC_000012.11:g.103232219_103232222del",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*736_*739del",
         "ENST00000553106.5:c.*736_*739del",
         "NM_000277.1:c.*736_*739del",
         "XM_011538422.1:c.*736_*739del"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742950",
      "grch38HGVS":"NC_000012.12:g.102838437C>T",
      "grch37HGVS":"NC_000012.11:g.103232215C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*738G>A",
         "ENST00000553106.5:c.*738G>A",
         "NM_000277.1:c.*738G>A",
         "XM_011538422.1:c.*738G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":3,
            "alleleNumber":8728,
            "alleleFrequency":0.000343721,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":1,
            "alleleNumber":836,
            "alleleFrequency":0.00119617,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3490,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":16,
            "alleleNumber":15004,
            "alleleFrequency":0.00106638,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":2,
            "alleleNumber":982,
            "alleleFrequency":0.00203666,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":6,
            "alleleNumber":13846,
            "alleleFrequency":0.000433338,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":16,
            "alleleNumber":17114,
            "alleleFrequency":0.000934907,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":22,
            "alleleNumber":30960,
            "alleleFrequency":0.000710594,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "clinVarSubmission":{
         "reviewStatus":"criteria provided, single submitter",
         "name":"NM_000277.2(PAH):c.*735T>C",
         "clinVarRCVs":[
            {
               "clinVarRCV":"RCV000388310",
               "significance":"Uncertain significance"
            }
         ],
         "clinVarSCVs":[
            {
               "clinVarSCV":"SCV000375556.2",
               "evaluationDate":"Jun 14, 2016",
               "significance":"Uncertain significance"
            }
         ],
         "variation":306910,
         "clinicalSignificance": "Uncertain significance"
      },
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA10639980",
      "grch38HGVS":"NC_000012.12:g.102838440A>G",
      "grch37HGVS":"NC_000012.11:g.103232218A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*735T>C",
         "ENST00000553106.5:c.*735T>C",
         "NM_000277.1:c.*735T>C",
         "XM_011538422.1:c.*735T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00119617
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8726,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":1,
            "alleleNumber":1614,
            "alleleFrequency":0.000619579,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3486,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15004,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13844,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17106,
            "alleleFrequency":5.8459e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30950,
            "alleleFrequency":3.23102e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA607426723",
      "grch38HGVS":"NC_000012.12:g.102838441C>T",
      "grch37HGVS":"NC_000012.11:g.103232219C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*734G>A",
         "ENST00000553106.5:c.*734G>A",
         "NM_000277.1:c.*734G>A",
         "XM_011538422.1:c.*734G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000619579
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742953",
      "grch38HGVS":"NC_000012.12:g.102838445G>A",
      "grch37HGVS":"NC_000012.11:g.103232223G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*730C>T",
         "ENST00000553106.5:c.*730C>T",
         "NM_000277.1:c.*730C>T",
         "XM_011538422.1:c.*730C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742955",
      "grch38HGVS":"NC_000012.12:g.102838448T>G",
      "grch37HGVS":"NC_000012.11:g.103232226T>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*727A>C",
         "ENST00000553106.5:c.*727A>C",
         "NM_000277.1:c.*727A>C",
         "XM_011538422.1:c.*727A>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000619579
         },
         {
            "method":"gnomad-exome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000619579
         }         
      ],

   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8736,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":1,
            "alleleNumber":836,
            "alleleFrequency":0.00119617,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15002,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":1,
            "alleleNumber":13854,
            "alleleFrequency":7.21813e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":0,
            "alleleNumber":17112,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30966,
            "alleleFrequency":3.22935e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742957",
      "grch38HGVS":"NC_000012.12:g.102838461T>A",
      "grch37HGVS":"NC_000012.11:g.103232239T>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*714A>T",
         "ENST00000553106.5:c.*714A>T",
         "NM_000277.1:c.*714A>T",
         "XM_011538422.1:c.*714A>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "RF"
            ],
            "popmax":0.00119617
         }
      ]
   },
   {
      "caid":"CA682819042",
      "grch38HGVS":"NC_000012.12:g.102838461T>C",
      "grch37HGVS":"NC_000012.11:g.103232239T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*714A>G",
         "ENST00000553106.5:c.*714A>G",
         "NM_000277.1:c.*714A>G",
         "XM_011538422.1:c.*714A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819044",
      "grch38HGVS":"NC_000012.12:g.102838472A>G",
      "grch37HGVS":"NC_000012.11:g.103232250A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*703T>C",
         "ENST00000553106.5:c.*703T>C",
         "NM_000277.1:c.*703T>C",
         "XM_011538422.1:c.*703T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819045",
      "grch38HGVS":"NC_000012.12:g.102838473A>C",
      "grch37HGVS":"NC_000012.11:g.103232251A>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*702T>G",
         "ENST00000553106.5:c.*702T>G",
         "NM_000277.1:c.*702T>G",
         "XM_011538422.1:c.*702T>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":23,
            "alleleNumber":8728,
            "alleleFrequency":0.0026352,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":834,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":14996,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":9,
            "alleleNumber":13850,
            "alleleFrequency":0.000649819,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":14,
            "alleleNumber":17098,
            "alleleFrequency":0.000818809,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":23,
            "alleleNumber":30948,
            "alleleFrequency":0.000743182,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742959",
      "grch38HGVS":"NC_000012.12:g.102838476G>A",
      "grch37HGVS":"NC_000012.11:g.103232254G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*699C>T",
         "ENST00000553106.5:c.*699C>T",
         "NM_000277.1:c.*699C>T",
         "XM_011538422.1:c.*699C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.0026352
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742960",
      "grch38HGVS":"NC_000012.12:g.102838482A>G",
      "grch37HGVS":"NC_000012.11:g.103232260A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*693T>C",
         "ENST00000553106.5:c.*693T>C",
         "NM_000277.1:c.*693T>C",
         "XM_011538422.1:c.*693T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819050",
      "grch38HGVS":"NC_000012.12:g.102838483A>G",
      "grch37HGVS":"NC_000012.11:g.103232261A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*692T>C",
         "ENST00000553106.5:c.*692T>C",
         "NM_000277.1:c.*692T>C",
         "XM_011538422.1:c.*692T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819051",
      "grch38HGVS":"NC_000012.12:g.102838498C>T",
      "grch37HGVS":"NC_000012.11:g.103232276C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*677G>A",
         "ENST00000553106.5:c.*677G>A",
         "NM_000277.1:c.*677G>A",
         "XM_011538422.1:c.*677G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742962",
      "grch38HGVS":"NC_000012.12:g.102838501A>G",
      "grch37HGVS":"NC_000012.11:g.103232279A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*674T>C",
         "ENST00000553106.5:c.*674T>C",
         "NM_000277.1:c.*674T>C",
         "XM_011538422.1:c.*674T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":2,
            "alleleNumber":8728,
            "alleleFrequency":0.000229148,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":836,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1614,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3488,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15000,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":976,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13844,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":2,
            "alleleNumber":17100,
            "alleleFrequency":0.000116959,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":2,
            "alleleNumber":30944,
            "alleleFrequency":6.46329e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742964",
      "grch38HGVS":"NC_000012.12:g.102838502C>G",
      "grch37HGVS":"NC_000012.11:g.103232280C>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*673G>C",
         "ENST00000553106.5:c.*673G>C",
         "NM_000277.1:c.*673G>C",
         "XM_011538422.1:c.*673G>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000229148
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742966",
      "grch38HGVS":"NC_000012.12:g.102838508A>T",
      "grch37HGVS":"NC_000012.11:g.103232286A>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*667T>A",
         "ENST00000553106.5:c.*667T>A",
         "NM_000277.1:c.*667T>A",
         "XM_011538422.1:c.*667T>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742968",
      "grch38HGVS":"NC_000012.12:g.102838514T>C",
      "grch37HGVS":"NC_000012.11:g.103232292T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*661A>G",
         "ENST00000553106.5:c.*661A>G",
         "NM_000277.1:c.*661A>G",
         "XM_011538422.1:c.*661A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742970",
      "grch38HGVS":"NC_000012.12:g.102838523G>C",
      "grch37HGVS":"NC_000012.11:g.103232301G>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*652C>G",
         "ENST00000553106.5:c.*652C>G",
         "NM_000277.1:c.*652C>G",
         "XM_011538422.1:c.*652C>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8734,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":92,
            "alleleNumber":3482,
            "alleleFrequency":0.0264216,
            "homozygousAlleleIndividualCount":1
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":63,
            "alleleNumber":15008,
            "alleleFrequency":0.00419776,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":14,
            "alleleNumber":982,
            "alleleFrequency":0.0142566,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":87,
            "alleleNumber":13850,
            "alleleFrequency":0.00628159,
            "homozygousAlleleIndividualCount":1
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":82,
            "alleleNumber":17114,
            "alleleFrequency":0.0047914,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":169,
            "alleleNumber":30964,
            "alleleFrequency":0.00545795,
            "homozygousAlleleIndividualCount":1
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742972",
      "grch38HGVS":"NC_000012.12:g.102838525T>C",
      "grch37HGVS":"NC_000012.11:g.103232303T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*650A>G",
         "ENST00000553106.5:c.*650A>G",
         "NM_000277.1:c.*650A>G",
         "XM_011538422.1:c.*650A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.0264216
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":15,
            "alleleNumber":8736,
            "alleleFrequency":0.00171703,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":836,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3482,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15008,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":12,
            "alleleNumber":13850,
            "alleleFrequency":0.000866426,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":3,
            "alleleNumber":17114,
            "alleleFrequency":0.000175295,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":15,
            "alleleNumber":30964,
            "alleleFrequency":0.000484434,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742974",
      "grch38HGVS":"NC_000012.12:g.102838527C>A",
      "grch37HGVS":"NC_000012.11:g.103232305C>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*648G>T",
         "ENST00000553106.5:c.*648G>T",
         "NM_000277.1:c.*648G>T",
         "XM_011538422.1:c.*648G>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00171703
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8732,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3482,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":2,
            "alleleNumber":15008,
            "alleleFrequency":0.000133262,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13850,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":2,
            "alleleNumber":17112,
            "alleleFrequency":0.000116877,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":2,
            "alleleNumber":30962,
            "alleleFrequency":6.45953e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742976",
      "grch38HGVS":"NC_000012.12:g.102838529G>A",
      "grch37HGVS":"NC_000012.11:g.103232307G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*646C>T",
         "ENST00000553106.5:c.*646C>T",
         "NM_000277.1:c.*646C>T",
         "XM_011538422.1:c.*646C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000133262
         }
      ]
   },
   {
      "caid":"CA682819069",
      "grch38HGVS":"NC_000012.12:g.102838549G>C",
      "grch37HGVS":"NC_000012.11:g.103232327G>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*626C>G",
         "ENST00000553106.5:c.*626C>G",
         "NM_000277.1:c.*626C>G",
         "XM_011538422.1:c.*626C>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819070",
      "grch38HGVS":"NC_000012.12:g.102838554G>C",
      "grch37HGVS":"NC_000012.11:g.103232332G>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*621C>G",
         "ENST00000553106.5:c.*621C>G",
         "NM_000277.1:c.*621C>G",
         "XM_011538422.1:c.*621C>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742977",
      "grch38HGVS":"NC_000012.12:g.102838558T>C",
      "grch37HGVS":"NC_000012.11:g.103232336T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*617A>G",
         "ENST00000553106.5:c.*617A>G",
         "NM_000277.1:c.*617A>G",
         "XM_011538422.1:c.*617A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA655173656",
      "grch38HGVS":"NC_000012.12:g.102838560T>C",
      "grch37HGVS":"NC_000012.11:g.103232338T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*615A>G",
         "ENST00000553106.5:c.*615A>G",
         "NM_000277.1:c.*615A>G",
         "XM_011538422.1:c.*615A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA655173657",
      "grch38HGVS":"NC_000012.12:g.102838569_102838570insG",
      "grch37HGVS":"NC_000012.11:g.103232347_103232348insG",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*605_*606insC",
         "ENST00000553106.5:c.*605_*606insC",
         "NM_000277.1:c.*605_*606insC",
         "XM_011538422.1:c.*605_*606insC"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742979",
      "grch38HGVS":"NC_000012.12:g.102838570C>T",
      "grch37HGVS":"NC_000012.11:g.103232348C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*605G>A",
         "ENST00000553106.5:c.*605G>A",
         "NM_000277.1:c.*605G>A",
         "XM_011538422.1:c.*605G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819075",
      "grch38HGVS":"NC_000012.12:g.102838572G>T",
      "grch37HGVS":"NC_000012.11:g.103232350G>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*603C>A",
         "ENST00000553106.5:c.*603C>A",
         "NM_000277.1:c.*603C>A",
         "XM_011538422.1:c.*603C>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8732,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1620,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3476,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":1,
            "alleleNumber":15008,
            "alleleFrequency":6.66311e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13844,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17112,
            "alleleFrequency":5.84385e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30956,
            "alleleFrequency":3.23039e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA607426799",
      "grch38HGVS":"NC_000012.12:g.102838582T>A",
      "grch37HGVS":"NC_000012.11:g.103232360T>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*593A>T",
         "ENST00000553106.5:c.*593A>T",
         "NM_000277.1:c.*593A>T",
         "XM_011538422.1:c.*593A>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":6.66311e-05
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":9,
            "alleleNumber":8734,
            "alleleFrequency":0.00103046,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":836,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3478,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15006,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":978,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":8,
            "alleleNumber":13844,
            "alleleFrequency":0.000577868,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17108,
            "alleleFrequency":5.84522e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":9,
            "alleleNumber":30952,
            "alleleFrequency":0.000290773,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742981",
      "grch38HGVS":"NC_000012.12:g.102838585T>C",
      "grch37HGVS":"NC_000012.11:g.103232363T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*590A>G",
         "ENST00000553106.5:c.*590A>G",
         "NM_000277.1:c.*590A>G",
         "XM_011538422.1:c.*590A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00103046
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742983",
      "grch38HGVS":"NC_000012.12:g.102838596C>T",
      "grch37HGVS":"NC_000012.11:g.103232374C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*579G>A",
         "ENST00000553106.5:c.*579G>A",
         "NM_000277.1:c.*579G>A",
         "XM_011538422.1:c.*579G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742985",
      "grch38HGVS":"NC_000012.12:g.102838600C>A",
      "grch37HGVS":"NC_000012.11:g.103232378C>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*575G>T",
         "ENST00000553106.5:c.*575G>T",
         "NM_000277.1:c.*575G>T",
         "XM_011538422.1:c.*575G>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA242742987",
      "grch38HGVS":"NC_000012.12:g.102838607G>A",
      "grch37HGVS":"NC_000012.11:g.103232385G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*568C>T",
         "ENST00000553106.5:c.*568C>T",
         "NM_000277.1:c.*568C>T",
         "XM_011538422.1:c.*568C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":1,
            "alleleNumber":8726,
            "alleleFrequency":0.0001146,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":836,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3450,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":14976,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":976,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":1,
            "alleleNumber":13806,
            "alleleFrequency":7.24323e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":0,
            "alleleNumber":17076,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30882,
            "alleleFrequency":3.23813e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA607426800",
      "grch38HGVS":"NC_000012.12:g.102838607G>T",
      "grch37HGVS":"NC_000012.11:g.103232385G>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*568C>A",
         "ENST00000553106.5:c.*568C>A",
         "NM_000277.1:c.*568C>A",
         "XM_011538422.1:c.*568C>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.0001146
         }
      ]
   },
   {
      "caid":"CA682819098",
      "grch38HGVS":"NC_000012.12:g.102838612_102838617del",
      "grch37HGVS":"NC_000012.11:g.103232390_103232395del",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*560_*565del",
         "ENST00000553106.5:c.*560_*565del",
         "NM_000277.1:c.*560_*565del",
         "XM_011538422.1:c.*560_*565del"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8730,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":836,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3474,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":1,
            "alleleNumber":15000,
            "alleleFrequency":6.66667e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":1,
            "alleleNumber":13834,
            "alleleFrequency":7.22857e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":0,
            "alleleNumber":17108,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30942,
            "alleleFrequency":3.23185e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant"
      ],
      "caid":"CA607426801",
      "grch38HGVS":"NC_000012.12:g.102838615A>T",
      "grch37HGVS":"NC_000012.11:g.103232393A>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*560T>A",
         "ENST00000553106.5:c.*560T>A",
         "NM_000277.1:c.*560T>A",
         "XM_011538422.1:c.*560T>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":6.66667e-05
         }
      ]
   },
   {
      "caid":"CA682819108",
      "grch38HGVS":"NC_000012.12:g.102838640A>G",
      "grch37HGVS":"NC_000012.11:g.103232418A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*535T>C",
         "ENST00000553106.5:c.*535T>C",
         "NM_000277.1:c.*535T>C",
         "XM_011538422.1:c.*535T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242742989",
      "grch38HGVS":"NC_000012.12:g.102838650C>G",
      "grch37HGVS":"NC_000012.11:g.103232428C>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*525G>C",
         "ENST00000553106.5:c.*525G>C",
         "NM_000277.1:c.*525G>C",
         "XM_011538422.1:c.*525G>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242742992",
      "grch38HGVS":"NC_000012.12:g.102838671T>C",
      "grch37HGVS":"NC_000012.11:g.103232449T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*504A>G",
         "ENST00000553106.5:c.*504A>G",
         "NM_000277.1:c.*504A>G",
         "XM_011538422.1:c.*504A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819110",
      "grch38HGVS":"NC_000012.12:g.102838690G>T",
      "grch37HGVS":"NC_000012.11:g.103232468G>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*485C>A",
         "ENST00000553106.5:c.*485C>A",
         "NM_000277.1:c.*485C>A",
         "XM_011538422.1:c.*485C>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242742994",
      "grch38HGVS":"NC_000012.12:g.102838694A>C",
      "grch37HGVS":"NC_000012.11:g.103232472A>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*481T>G",
         "ENST00000553106.5:c.*481T>G",
         "NM_000277.1:c.*481T>G",
         "XM_011538422.1:c.*481T>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242742997",
      "grch38HGVS":"NC_000012.12:g.102838695A>G",
      "grch37HGVS":"NC_000012.11:g.103232473A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*480T>C",
         "ENST00000553106.5:c.*480T>C",
         "NM_000277.1:c.*480T>C",
         "XM_011538422.1:c.*480T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":1,
            "alleleNumber":8732,
            "alleleFrequency":0.000114521,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":14994,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":1,
            "alleleNumber":13850,
            "alleleFrequency":7.22022e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":0,
            "alleleNumber":17108,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30958,
            "alleleFrequency":3.23018e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242742998",
      "grch38HGVS":"NC_000012.12:g.102838701A>G",
      "grch37HGVS":"NC_000012.11:g.103232479A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*474T>C",
         "ENST00000553106.5:c.*474T>C",
         "NM_000277.1:c.*474T>C",
         "XM_011538422.1:c.*474T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000114521
         }
      ]
   },
   {
      "caid":"CA682819113",
      "grch38HGVS":"NC_000012.12:g.102838709A>G",
      "grch37HGVS":"NC_000012.11:g.103232487A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*466T>C",
         "ENST00000553106.5:c.*466T>C",
         "NM_000277.1:c.*466T>C",
         "XM_011538422.1:c.*466T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743000",
      "grch38HGVS":"NC_000012.12:g.102838710T>C",
      "grch37HGVS":"NC_000012.11:g.103232488T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*465A>G",
         "ENST00000553106.5:c.*465A>G",
         "NM_000277.1:c.*465A>G",
         "XM_011538422.1:c.*465A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8728,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":1,
            "alleleNumber":1618,
            "alleleFrequency":0.000618047,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3486,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15002,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13852,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17104,
            "alleleFrequency":5.84659e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30956,
            "alleleFrequency":3.23039e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743002",
      "grch38HGVS":"NC_000012.12:g.102838719T>C",
      "grch37HGVS":"NC_000012.11:g.103232497T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*456A>G",
         "ENST00000553106.5:c.*456A>G",
         "NM_000277.1:c.*456A>G",
         "XM_011538422.1:c.*456A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000618047
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8726,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":1,
            "alleleNumber":1620,
            "alleleFrequency":0.000617284,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3488,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":14994,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":1,
            "alleleNumber":13844,
            "alleleFrequency":7.22335e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":0,
            "alleleNumber":17104,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30948,
            "alleleFrequency":3.23123e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426802",
      "grch38HGVS":"NC_000012.12:g.102838732T>C",
      "grch37HGVS":"NC_000012.11:g.103232510T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*443A>G",
         "ENST00000553106.5:c.*443A>G",
         "NM_000277.1:c.*443A>G",
         "XM_011538422.1:c.*443A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000617284
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":1,
            "alleleNumber":8734,
            "alleleFrequency":0.000114495,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15000,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13854,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17112,
            "alleleFrequency":5.84385e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30966,
            "alleleFrequency":3.22935e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426803",
      "grch38HGVS":"NC_000012.12:g.102838747A>T",
      "grch37HGVS":"NC_000012.11:g.103232525A>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*428T>A",
         "ENST00000553106.5:c.*428T>A",
         "NM_000277.1:c.*428T>A",
         "XM_011538422.1:c.*428T>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000114495
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743004",
      "grch38HGVS":"NC_000012.12:g.102838748T>C",
      "grch37HGVS":"NC_000012.11:g.103232526T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*427A>G",
         "ENST00000553106.5:c.*427A>G",
         "NM_000277.1:c.*427A>G",
         "XM_011538422.1:c.*427A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8728,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3488,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":1,
            "alleleNumber":14998,
            "alleleFrequency":6.66756e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13850,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17104,
            "alleleFrequency":5.84659e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30954,
            "alleleFrequency":3.2306e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743006",
      "grch38HGVS":"NC_000012.12:g.102838755C>T",
      "grch37HGVS":"NC_000012.11:g.103232533C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*420G>A",
         "ENST00000553106.5:c.*420G>A",
         "NM_000277.1:c.*420G>A",
         "XM_011538422.1:c.*420G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":6.66756e-05
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743008",
      "grch38HGVS":"NC_000012.12:g.102838756G>A",
      "grch37HGVS":"NC_000012.11:g.103232534G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*419C>T",
         "ENST00000553106.5:c.*419C>T",
         "NM_000277.1:c.*419C>T",
         "XM_011538422.1:c.*419C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819127",
      "grch38HGVS":"NC_000012.12:g.102838757A>G",
      "grch37HGVS":"NC_000012.11:g.103232535A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*418T>C",
         "ENST00000553106.5:c.*418T>C",
         "NM_000277.1:c.*418T>C",
         "XM_011538422.1:c.*418T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":1,
            "alleleNumber":8734,
            "alleleFrequency":0.000114495,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":14998,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13852,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17110,
            "alleleFrequency":5.84454e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30962,
            "alleleFrequency":3.22977e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426804",
      "grch38HGVS":"NC_000012.12:g.102838765T>G",
      "grch37HGVS":"NC_000012.11:g.103232543T>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*410A>C",
         "ENST00000553106.5:c.*410A>C",
         "NM_000277.1:c.*410A>C",
         "XM_011538422.1:c.*410A>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000114495
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8734,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":1,
            "alleleNumber":302,
            "alleleFrequency":0.00331126,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3490,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15006,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13858,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17112,
            "alleleFrequency":5.84385e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30970,
            "alleleFrequency":3.22893e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743010",
      "grch38HGVS":"NC_000012.12:g.102838777T>C",
      "grch37HGVS":"NC_000012.11:g.103232555T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*398A>G",
         "ENST00000553106.5:c.*398A>G",
         "NM_000277.1:c.*398A>G",
         "XM_011538422.1:c.*398A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00331126
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743012",
      "grch38HGVS":"NC_000012.12:g.102838780A>C",
      "grch37HGVS":"NC_000012.11:g.103232558A>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*395T>G",
         "ENST00000553106.5:c.*395T>G",
         "NM_000277.1:c.*395T>G",
         "XM_011538422.1:c.*395T>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8730,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":2,
            "alleleNumber":1616,
            "alleleFrequency":0.00123762,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15008,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":982,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13856,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":2,
            "alleleNumber":17112,
            "alleleFrequency":0.000116877,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":2,
            "alleleNumber":30968,
            "alleleFrequency":6.45828e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426805",
      "grch38HGVS":"NC_000012.12:g.102838781A>G",
      "grch37HGVS":"NC_000012.11:g.103232559A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*394T>C",
         "ENST00000553106.5:c.*394T>C",
         "NM_000277.1:c.*394T>C",
         "XM_011538422.1:c.*394T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00123762
         }
      ]
   },
   {
      "caid":"CA682819137",
      "grch38HGVS":"NC_000012.12:g.102838787A>G",
      "grch37HGVS":"NC_000012.11:g.103232565A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*388T>C",
         "ENST00000553106.5:c.*388T>C",
         "NM_000277.1:c.*388T>C",
         "XM_011538422.1:c.*388T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819138",
      "grch38HGVS":"NC_000012.12:g.102838789G>C",
      "grch37HGVS":"NC_000012.11:g.103232567G>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*386C>G",
         "ENST00000553106.5:c.*386C>G",
         "NM_000277.1:c.*386C>G",
         "XM_011538422.1:c.*386C>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819143",
      "grch38HGVS":"NC_000012.12:g.102838791C>T",
      "grch37HGVS":"NC_000012.11:g.103232569C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*384G>A",
         "ENST00000553106.5:c.*384G>A",
         "NM_000277.1:c.*384G>A",
         "XM_011538422.1:c.*384G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743014",
      "grch38HGVS":"NC_000012.12:g.102838800G>C",
      "grch37HGVS":"NC_000012.11:g.103232578G>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*375C>G",
         "ENST00000553106.5:c.*375C>G",
         "NM_000277.1:c.*375C>G",
         "XM_011538422.1:c.*375C>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":3,
            "alleleNumber":8730,
            "alleleFrequency":0.000343643,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15002,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":2,
            "alleleNumber":13854,
            "alleleFrequency":0.000144363,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17106,
            "alleleFrequency":5.8459e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":3,
            "alleleNumber":30960,
            "alleleFrequency":9.68992e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743016",
      "grch38HGVS":"NC_000012.12:g.102838807A>T",
      "grch37HGVS":"NC_000012.11:g.103232585A>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*368T>A",
         "ENST00000553106.5:c.*368T>A",
         "NM_000277.1:c.*368T>A",
         "XM_011538422.1:c.*368T>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000343643
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":1,
            "alleleNumber":8734,
            "alleleFrequency":0.000114495,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15006,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13858,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17110,
            "alleleFrequency":5.84454e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30968,
            "alleleFrequency":3.22914e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743018",
      "grch38HGVS":"NC_000012.12:g.102838808T>C",
      "grch37HGVS":"NC_000012.11:g.103232586T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*367A>G",
         "ENST00000553106.5:c.*367A>G",
         "NM_000277.1:c.*367A>G",
         "XM_011538422.1:c.*367A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000114495
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743020",
      "grch38HGVS":"NC_000012.12:g.102838814C>T",
      "grch37HGVS":"NC_000012.11:g.103232592C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*361G>A",
         "ENST00000553106.5:c.*361G>A",
         "NM_000277.1:c.*361G>A",
         "XM_011538422.1:c.*361G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743022",
      "grch38HGVS":"NC_000012.12:g.102838819A>G",
      "grch37HGVS":"NC_000012.11:g.103232597A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*356T>C",
         "ENST00000553106.5:c.*356T>C",
         "NM_000277.1:c.*356T>C",
         "XM_011538422.1:c.*356T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743024",
      "grch38HGVS":"NC_000012.12:g.102838822C>G",
      "grch37HGVS":"NC_000012.11:g.103232600C>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*353G>C",
         "ENST00000553106.5:c.*353G>C",
         "NM_000277.1:c.*353G>C",
         "XM_011538422.1:c.*353G>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743027",
      "grch38HGVS":"NC_000012.12:g.102838825C>T",
      "grch37HGVS":"NC_000012.11:g.103232603C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*350G>A",
         "ENST00000553106.5:c.*350G>A",
         "NM_000277.1:c.*350G>A",
         "XM_011538422.1:c.*350G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8734,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3490,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":1,
            "alleleNumber":15000,
            "alleleFrequency":6.66667e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":978,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13854,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17104,
            "alleleFrequency":5.84659e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30958,
            "alleleFrequency":3.23018e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743029",
      "grch38HGVS":"NC_000012.12:g.102838833G>A",
      "grch37HGVS":"NC_000012.11:g.103232611G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*342C>T",
         "ENST00000553106.5:c.*342C>T",
         "NM_000277.1:c.*342C>T",
         "XM_011538422.1:c.*342C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":6.66667e-05
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8730,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":1,
            "alleleNumber":1616,
            "alleleFrequency":0.000618812,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15002,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13858,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17102,
            "alleleFrequency":5.84727e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30960,
            "alleleFrequency":3.22997e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426806",
      "grch38HGVS":"NC_000012.12:g.102838836C>A",
      "grch37HGVS":"NC_000012.11:g.103232614C>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*339G>T",
         "ENST00000553106.5:c.*339G>T",
         "NM_000277.1:c.*339G>T",
         "XM_011538422.1:c.*339G>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000618812
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8734,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3490,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":1,
            "alleleNumber":15006,
            "alleleFrequency":6.664e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13860,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17106,
            "alleleFrequency":5.8459e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30966,
            "alleleFrequency":3.22935e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426807",
      "grch38HGVS":"NC_000012.12:g.102838839T>G",
      "grch37HGVS":"NC_000012.11:g.103232617T>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*336A>C",
         "ENST00000553106.5:c.*336A>C",
         "NM_000277.1:c.*336A>C",
         null,
         "XM_011538422.1:c.*336A>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         null,
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":6.664e-05
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743031",
      "grch38HGVS":"NC_000012.12:g.102838841C>A",
      "grch37HGVS":"NC_000012.11:g.103232619C>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*334G>T",
         "ENST00000553106.5:c.*334G>T",
         "NM_000277.1:c.*334G>T",
         "XM_011538422.1:c.*334G>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743033",
      "grch38HGVS":"NC_000012.12:g.102838848T>C",
      "grch37HGVS":"NC_000012.11:g.103232626T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*327A>G",
         "ENST00000553106.5:c.*327A>G",
         "NM_000277.1:c.*327A>G",
         "XM_011538422.1:c.*327A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743035",
      "grch38HGVS":"NC_000012.12:g.102838848T>G",
      "grch37HGVS":"NC_000012.11:g.103232626T>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*327A>C",
         "ENST00000553106.5:c.*327A>C",
         "NM_000277.1:c.*327A>C",
         "XM_011538422.1:c.*327A>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8736,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":1,
            "alleleNumber":15006,
            "alleleFrequency":6.664e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13860,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17110,
            "alleleFrequency":5.84454e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30970,
            "alleleFrequency":3.22893e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426808",
      "grch38HGVS":"NC_000012.12:g.102838852T>A",
      "grch37HGVS":"NC_000012.11:g.103232630T>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*323A>T",
         "ENST00000553106.5:c.*323A>T",
         "NM_000277.1:c.*323A>T",
         "XM_011538422.1:c.*323A>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":6.664e-05
         }
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8736,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15006,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":1,
            "alleleNumber":980,
            "alleleFrequency":0.00102041,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13860,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17110,
            "alleleFrequency":5.84454e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30970,
            "alleleFrequency":3.22893e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426809",
      "grch38HGVS":"NC_000012.12:g.102838852T>C",
      "grch37HGVS":"NC_000012.11:g.103232630T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*323A>G",
         "ENST00000553106.5:c.*323A>G",
         "NM_000277.1:c.*323A>G",
         "XM_011538422.1:c.*323A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":null
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743038",
      "grch38HGVS":"NC_000012.12:g.102838869A>G",
      "grch37HGVS":"NC_000012.11:g.103232647A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*306T>C",
         "ENST00000553106.5:c.*306T>C",
         "NM_000277.1:c.*306T>C",
         "XM_011538422.1:c.*306T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8726,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":834,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1614,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3490,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":2,
            "alleleNumber":15000,
            "alleleFrequency":0.000133333,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":980,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13850,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":2,
            "alleleNumber":17096,
            "alleleFrequency":0.000116986,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":2,
            "alleleNumber":30946,
            "alleleFrequency":6.46287e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426810",
      "grch38HGVS":"NC_000012.12:g.102838871G>T",
      "grch37HGVS":"NC_000012.11:g.103232649G>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*304C>A",
         "ENST00000553106.5:c.*304C>A",
         "NM_000277.1:c.*304C>A",
         "XM_011538422.1:c.*304C>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.000133333
         }
      ]
   },
   {
      "caid":"CA682819166",
      "grch38HGVS":"NC_000012.12:g.102838887A>T",
      "grch37HGVS":"NC_000012.11:g.103232665A>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*288T>A",
         "ENST00000553106.5:c.*288T>A",
         "NM_000277.1:c.*288T>A",
         "XM_011538422.1:c.*288T>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743039",
      "grch38HGVS":"NC_000012.12:g.102838889T>C",
      "grch37HGVS":"NC_000012.11:g.103232667T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*286A>G",
         "ENST00000553106.5:c.*286A>G",
         "NM_000277.1:c.*286A>G",
         "XM_011538422.1:c.*286A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819169",
      "grch38HGVS":"NC_000012.12:g.102838895T>G",
      "grch37HGVS":"NC_000012.11:g.103232673T>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*280A>C",
         "ENST00000553106.5:c.*280A>C",
         "NM_000277.1:c.*280A>C",
         "XM_011538422.1:c.*280A>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819171",
      "grch38HGVS":"NC_000012.12:g.102838896G>T",
      "grch37HGVS":"NC_000012.11:g.103232674G>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*279C>A",
         "ENST00000553106.5:c.*279C>A",
         "NM_000277.1:c.*279C>A",
         "XM_011538422.1:c.*279C>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743042",
      "grch38HGVS":"NC_000012.12:g.102838902C>G",
      "grch37HGVS":"NC_000012.11:g.103232680C>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*273G>C",
         "ENST00000553106.5:c.*273G>C",
         "NM_000277.1:c.*273G>C",
         "XM_011538422.1:c.*273G>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743044",
      "grch38HGVS":"NC_000012.12:g.102838902C>T",
      "grch37HGVS":"NC_000012.11:g.103232680C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*273G>A",
         "ENST00000553106.5:c.*273G>A",
         "NM_000277.1:c.*273G>A",
         "XM_011538422.1:c.*273G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8730,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1614,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3488,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":1,
            "alleleNumber":14998,
            "alleleFrequency":6.66756e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":978,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13848,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17100,
            "alleleFrequency":5.84795e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30948,
            "alleleFrequency":3.23123e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743046",
      "grch38HGVS":"NC_000012.12:g.102838903G>A",
      "grch37HGVS":"NC_000012.11:g.103232681G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*272C>T",
         "ENST00000553106.5:c.*272C>T",
         "NM_000277.1:c.*272C>T",
         "XM_011538422.1:c.*272C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":6.66756e-05
         }
      ]
   },
   {
      "caid":"CA682819174",
      "grch38HGVS":"NC_000012.12:g.102838907G>A",
      "grch37HGVS":"NC_000012.11:g.103232685G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*268C>T",
         "ENST00000553106.5:c.*268C>T",
         "NM_000277.1:c.*268C>T",
         "XM_011538422.1:c.*268C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743048",
      "grch38HGVS":"NC_000012.12:g.102838908A>G",
      "grch37HGVS":"NC_000012.11:g.103232686A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*267T>C",
         "ENST00000553106.5:c.*267T>C",
         "NM_000277.1:c.*267T>C",
         "XM_011538422.1:c.*267T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819176",
      "grch38HGVS":"NC_000012.12:g.102838913T>C",
      "grch37HGVS":"NC_000012.11:g.103232691T>C",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*262A>G",
         "ENST00000553106.5:c.*262A>G",
         "NM_000277.1:c.*262A>G",
         "XM_011538422.1:c.*262A>G"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8724,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":1,
            "alleleNumber":302,
            "alleleFrequency":0.00331126,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1618,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3488,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15002,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":978,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13852,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17098,
            "alleleFrequency":5.84864e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30950,
            "alleleFrequency":3.23102e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426811",
      "grch38HGVS":"NC_000012.12:g.102838914G>T",
      "grch37HGVS":"NC_000012.11:g.103232692G>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*261C>A",
         "ENST00000553106.5:c.*261C>A",
         "NM_000277.1:c.*261C>A",
         "XM_011538422.1:c.*261C>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00331126
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743050",
      "grch38HGVS":"NC_000012.12:g.102838921C>T",
      "grch37HGVS":"NC_000012.11:g.103232699C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*254G>A",
         "ENST00000553106.5:c.*254G>A",
         "NM_000277.1:c.*254G>A",
         "XM_011538422.1:c.*254G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA655173658",
      "grch38HGVS":"NC_000012.12:g.102838923C>T",
      "grch37HGVS":"NC_000012.11:g.103232701C>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*252G>A",
         "ENST00000553106.5:c.*252G>A",
         "NM_000277.1:c.*252G>A",
         "XM_011538422.1:c.*252G>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819183",
      "grch38HGVS":"NC_000012.12:g.102838925A>G",
      "grch37HGVS":"NC_000012.11:g.103232703A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*250T>C",
         "ENST00000553106.5:c.*250T>C",
         "NM_000277.1:c.*250T>C",
         "XM_011538422.1:c.*250T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743051",
      "grch38HGVS":"NC_000012.12:g.102838928G>A",
      "grch37HGVS":"NC_000012.11:g.103232706G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*247C>T",
         "ENST00000553106.5:c.*247C>T",
         "NM_000277.1:c.*247C>T",
         "XM_011538422.1:c.*247C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA655173659",
      "grch38HGVS":"NC_000012.12:g.102838928G>T",
      "grch37HGVS":"NC_000012.11:g.103232706G>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*247C>A",
         "ENST00000553106.5:c.*247C>A",
         "NM_000277.1:c.*247C>A",
         "XM_011538422.1:c.*247C>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8732,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":1,
            "alleleNumber":838,
            "alleleFrequency":0.00119332,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":0,
            "alleleNumber":302,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3488,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":14994,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":976,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13846,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17100,
            "alleleFrequency":5.84795e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30946,
            "alleleFrequency":3.23144e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743054",
      "grch38HGVS":"NC_000012.12:g.102838930G>A",
      "grch37HGVS":"NC_000012.11:g.103232708G>A",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*245C>T",
         "ENST00000553106.5:c.*245C>T",
         "NM_000277.1:c.*245C>T",
         "XM_011538422.1:c.*245C>T"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00119332
         }
      ]
   },
   {
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA242743056",
      "grch38HGVS":"NC_000012.12:g.102838934A>T",
      "grch37HGVS":"NC_000012.11:g.103232712A>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*241T>A",
         "ENST00000553106.5:c.*241T>A",
         "NM_000277.1:c.*241T>A",
         "XM_011538422.1:c.*241T>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "populationAlleleFrequencyStatements":[
         {
            "method":"gnomad-genome",
            "population":"African/African American",
            "alleleCount":0,
            "alleleNumber":8734,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Admixed American",
            "alleleCount":0,
            "alleleNumber":838,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Ashkenazi Jewish",
            "alleleCount":1,
            "alleleNumber":302,
            "alleleFrequency":0.00331126,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"East Asian",
            "alleleCount":0,
            "alleleNumber":1616,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Finnish",
            "alleleCount":0,
            "alleleNumber":3492,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Non-Finnish European",
            "alleleCount":0,
            "alleleNumber":15002,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Other",
            "alleleCount":0,
            "alleleNumber":976,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"South Asian",
            "alleleCount":0,
            "alleleNumber":0,
            "alleleFrequency":0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Female",
            "alleleCount":0,
            "alleleNumber":13856,
            "alleleFrequency":0.0,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"Male",
            "alleleCount":1,
            "alleleNumber":17104,
            "alleleFrequency":5.84659e-05,
            "homozygousAlleleIndividualCount":0
         },
         {
            "method":"gnomad-genome",
            "population":"combined",
            "alleleCount":1,
            "alleleNumber":30960,
            "alleleFrequency":3.22997e-05,
            "homozygousAlleleIndividualCount":0
         }
      ],
      "molecularConsequences":[
         "3_prime_UTR_variant",
         "downstream_gene_variant"
      ],
      "caid":"CA607426812",
      "grch38HGVS":"NC_000012.12:g.102838942A>T",
      "grch37HGVS":"NC_000012.11:g.103232720A>T",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*233T>A",
         "ENST00000553106.5:c.*233T>A",
         "NM_000277.1:c.*233T>A",
         "XM_011538422.1:c.*233T>A"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ],
      "PopulationAlleleFrequencyMetadata":[
         {
            "method":"gnomad-genome",
            "filters":[
               "PASS"
            ],
            "popmax":0.00331126
         }
      ]
   },
   {
      "caid":"CA682819195",
      "grch38HGVS":"NC_000012.12:g.102838943_102838944del",
      "grch37HGVS":"NC_000012.11:g.103232721_103232722del",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*231_*232del",
         "ENST00000553106.5:c.*231_*232del",
         "NM_000277.1:c.*231_*232del",
         "XM_011538422.1:c.*231_*232del"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   },
   {
      "caid":"CA682819198",
      "grch38HGVS":"NC_000012.12:g.102838945A>G",
      "grch37HGVS":"NC_000012.11:g.103232723A>G",
      "transcriptHGVS":[
         "ENST00000307000.7:c.*230T>C",
         "ENST00000553106.5:c.*230T>C",
         "NM_000277.1:c.*230T>C",
         "XM_011538422.1:c.*230T>C"
      ],
      "proteinEffects":[
         "ENSP00000303500.2:p.=",
         "ENSP00000448059.1:p.=",
         "NP_000268.1:p.=",
         "XP_011536724.1:p.="
      ]
   }
];

export default result;