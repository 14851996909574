import React from 'react';
import Table from 'react-bootstrap/Table';

import LoadingSpinner from '../../../common/LoadingSpinner';
import WarningIcon from "../../../common/WarningIcon";

/**
 * This component displays a warning with a tooltip next to a REVEL
 * score.
 *
 * TODO(Liam Mulhall): Remove this warning once we have a long-term solution for the REVEL score issue. See https://github.com/ClinGen/gci-vci-aws/issues/1378.
 *
 * @param {String|Array.<String>} score - REVEL score from myvariant.info.
 * @return {JSX.Element} - The warning and score.
 * @constructor
 */
const WarningAndScore = ({score}) => {
    const revelScoreWarning = "Warning: The REVEL score displayed may not correspond to the MANE transcript. We are working on a fix with the third party source that supplies this information. Please proceed with caution. You can view all REVEL scores and transcripts in the UCSC genome browser.";
    const revelScoreWarningID = "revel-score-warning";
    return (
        <div>
            <WarningIcon
                tooltip={revelScoreWarning}
                tooltipId={revelScoreWarningID}
            ></WarningIcon>
            {Array.isArray(score) ? score[0].toString() : score.toString()}
        </div>
    );
};

const ClinGenPredictorsTable = ({
  clinGenPred,
  clinGenPredStatic,
  isSingleNucleotide,
  isLoadingMyVariantInfo
}) => (
  <div className="card mb-3">
    <div className="card-header">
      <h4 className="m-0">ClinGen Predictors</h4>
    </div>
    {isLoadingMyVariantInfo
      ? <LoadingSpinner className="my-4" />
      : (
        <>
        {isSingleNucleotide && clinGenPred
          ? (
            <Table>
              <thead>
                <tr>
                  <th>Source</th>
                  <th>Score Range</th>
                  <th>Score</th>
                  <th>Impact Threshold</th>
                  <th>Prediction</th>
                </tr>
              </thead>
              <tbody>
                {
                  clinGenPredStatic._order.map((method) => (
                    clinGenPred[method].visible)
                      ? (
                        <tr key={method}>
                        <td>
                          {clinGenPredStatic._url[method]
                            ? (
                              <span>
                                <a
                                  href={clinGenPredStatic._url[method]}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {clinGenPredStatic._labels[method]}
                                </a>
                                {clinGenPredStatic._type[method]}
                              </span>
                            ) : (
                              <span>{clinGenPredStatic._type[method] + clinGenPredStatic._type[method]}</span>
                            )
                          }
                        </td>
                        <td>{clinGenPred[method].score_range}</td>
                        <td>
                            {clinGenPred[method].score ? <WarningAndScore score={clinGenPred[method].score}/> : 'No data found'}
                        </td>
                        <td>{clinGenPredStatic._pathoThreshold[method]}</td>
                        <td>{clinGenPred[method].prediction}</td>
                      </tr>
                    ) : null
                  )
                }
              </tbody>
            </Table>
          ) : (
            <div className="card-body"><span>These predictors only return data for missense variants.</span></div>
          )
        }
        </>
      )
    }
  </div>
);

export default ClinGenPredictorsTable;