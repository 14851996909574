/**
 * Method to convert a given array of objects to csv-formatted data
 * @param {object} args - Object consisting of an array
 */
const convertArrayOfObjectsToCSV = (args) => {
  let result, ctr, keys, columnDelimiter, lineDelimiter, data;

  data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  keys = Object.keys(data[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(function(item) {
    ctr = 0;
    keys.forEach(function(key) {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

/**
 * Method to export/download converted JSON data to .csv file
 * @param {array} arr - Array of objects
 * @param {object} args - Object consisting of a filename
 */
const exportCSV = (arr, args) => {
  let data, filename, link;

  let csv = convertArrayOfObjectsToCSV({
    data: arr
  });
  if (csv == null) return;

  filename = args.filename || 'data-export.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  data = encodeURI(csv);

  link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', filename);
  link.click();
};

export default exportCSV;
