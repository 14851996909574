/**
 * Method to return the source title given the annotation
 * @param {object} annotation  - annotation object
 */
export function getAnnotationSourceTitle (annotation) {
    const title = annotation?.article?.PK
    ? `PMID:${annotation.article.PK}`
    : annotation?.scv?.scvId
      ? `ClinVar SCV:${annotation.scv.scvId}`
      : "";

  return (title);
}
