//Hard-coded Code Strip values here for now

export const codeStripValues = 
    [{
            code:"BA1",
            class: "stand-alone",
            definition: "Allele frequency greater than 5% in a population database",
            definitionLong: "Allele frequency is > 5% in ExAC, 1000 Genomes, or ESP",
            category: "population",
            diseaseDependent: false,
            tabIndex: 1
    },
    {       code: "BS1",
            class: "benign-strong",
            definition: "MAF is too high for disorder",
            definitionLong: "Allele frequency greater than expected due to disorder",
            category: "population",
            diseaseDependent: true,
            tabIndex: 1
    },
    {       code: "BS2",
            class: "benign-strong",
            definition: "Observation in controls inconsistent with disease penetrance",
            definitionLong: "Observed in a healthy adult individual for a recessive (homozygous), dominant (heterozygous), or X-linked (hemizygous) disorder, with full penetrance expected at an early age",
            category: "segregation",
            diseaseDependent: true,
            tabIndex: 4
    },
    {       code: "BS3",
            class: "benign-strong",
            definition: "Well-established functional studies show no deleterious effect",
            definitionLong: "Well established <i>in vitro</i> or <i>in vivo</i> functional studies show no damaging effect on protein function or splicing",
            category: "functional",
            diseaseDependent: true,
            tabIndex: 3
    },
    {       code: "BS4",
            class: "benign-strong",
            definition: "Non-segregation with disease",
            definitionLong: "Lack of segregation in affected members of family (has caveat)",
            category: "segregation",
            diseaseDependent: true,
            tabIndex: 4
    },
    {       code: "BP1",
            class: "benign-supporting",
            definition: "Missense in gene where primarily truncating cause disease",
            definitionLong: "Missense variant in a gene for which primarily truncating variants are known to cause disease",
            category: "computational",
            diseaseDependent: true,
            tabIndex: 2,
            subTabIndex: 0
    },
    {       code: "BP2",
            class: "benign-supporting",
            definition: "Observed in trans with path. variant for dominant disorder or cis any inheritance",
            definitionLong: "Observed in <i>trans</i> with a pathogenic variant for a fully penetrant dominant gene/disorder or observed in <i>cis</i> with a pathogenic variant in any inheritance pattern",
            category: "segregation",
            diseaseDependent: true,
            tabIndex: 4
    },
    {       code: "BP3",
            class: "benign-supporting",
            definition: "In-frame indels in repeat w/out known function",
            definitionLong: "In-frame deletions/insertions in a repetitive region without a known function",
            category: "computational",
            diseaseDependent: false,
            tabIndex: 2,
            subTabIndex: 3
    },
    {       code: "BP4",
            class: "benign-supporting",
            definition: "Multiple lines of computational evidence suggest no impact on gene /gene product",
            definitionLong: "Multiple lines of computational evidence suggest no impact on gene or gene product (conservation, evolutionary, splicing impact, etc.) (has caveat)",
            category: "computational",
            diseaseDependent: false,
            tabIndex: 2,
            subTabIndex: 0
    },
{           code: "BP5",
            class: "benign-supporting",
            definition: "Found in case with an alternate cause",
            definitionLong: "Variant found in a case with an alternate molecular basis for disease",
            category: "segregation",
            diseaseDependent: true,
            tabIndex: 4
    },
    {       code: "BP6",
            class: "benign-supporting",
            definition: "Reputable source w/out shared data = benign",
            definitionLong: "Reputable source recently reports variant as benign, but the evidence is not available to the laboratory to perform an independent evaluation",
            category: "segregation",
            diseaseDependent: true,
            tabIndex: 4
    },
    {       code: "BP7",
            class: "benign-supporting",
            definition: "Silent variant predicted with no splice impact",
            definitionLong: "A synonymous (silent) variant for which splicing prediction algorithms predict no impact to the splice consensus sequence nor the creation of a new splice site AND the nucleotide is not highly conserved",
            category: "computational",
            diseaseDependent: false,
            tabIndex: 2,
            subTabIndex: 2
    },
    {       code: "PP1",
            class: "pathogenic-supporting",
            definition: "Cosegregation with disease in multiple affected family members",
            definitionLong: "Cosegregation with disease in multiple affected family members in a gene definitively known to cause disease",
            category: "segregation",
            diseaseDependent: true,
            tabIndex: 4
    },
    {       code: "PP2",
            class: "pathogenic-supporting",
            definition: "Missense in gene with low rate of benign missense variants and path. missenses common",
            definitionLong: "Missense variant in a gene that has a low rate of benign missense variation and in which missense variants are a common mechanism of disease",
            category: "computational",
            diseaseDependent: true,
            tabIndex: 2,
            subTabIndex: 0
    },
    {       code: "PP3",
            class: "pathogenic-supporting",
            definition: "Multiple lines of computational evidence support a deleterious effect on the gene /gene product",
            definitionLong: "Multiple lines of computational evidence support a deleterious effect on the gene or gene product (conservation, evolutionary, splicing impact, etc.) (has caveat)",
            category: "computational",
            diseaseDependent: false,
            tabIndex: 2,
            subTabIndex: 0
    },
    {       code: "PP4",
            class: "pathogenic-supporting",
            definition: "Patient's phenotype or FH highly specific for gene",
            definitionLong: "Patient's phenotype or family history is highly specific for a disease with a single genetic etiology",
            category: "segregation",
            diseaseDependent: true,
            tabIndex: 4
    },
    {       code: "PP5",
            class: "pathogenic-supporting",
            definition: "Reputable source = pathogenic",
            definitionLong: "Reputable source recently reports variant as pathogenic, but the evidence is not available to the laboratory to perform an independent evaluation",
            category: "segregation",
            diseaseDependent: true,
            tabIndex: 4
    },
    {   code: "PM1",
        class: "pathogenic-moderate",
        definition: "Mutational hot spot or well-studied functional domain without benign variation",
        definitionLong: "Located in a mutational hot spot and/or critical and well-established functional domain (e.g. active site of enzyme) without benign variation",
        category: "functional",
        diseaseDependent: true,
        tabIndex: 3
    },
    {   code: "PM2",
        class: "pathogenic-moderate",
        definition: "Absent in population databases",
        definitionLong: "Absent from controls (or at extremely low frequency if recessive) in ExAC, 1000 Genomes, or ESP",
        category: "population",
        diseaseDependent: false,
        tabIndex: 1
    },
    {   code: "PM3",
        class: "pathogenic-moderate",
        definition: "For recessive disorders, detected in trans with a pathogenic variant",
        definitionLong: "For recessive disorders, detected in <i>trans</i> with a pathogenic variant",
        category: "segregation",
        diseaseDependent: true,
        tabIndex: 4
    },
    {   code: "PM4",
        class: "pathogenic-moderate",
        definition: "Protein length changing variant",
        definitionLong: "Protein length changes as a result of in-frame deletions/insertions in a non-repeat region or stop-loss variant",
        category: "computational",
        diseaseDependent: false,
        tabIndex: 2,
        subTabIndex: 3
    },
    {   code: "PM5",
        class: "pathogenic-moderate",
        definition: "Novel missense change at an amino acid residue where a different pathogenic missense change has been seen before",
        definitionLong: " Novel missense change at an amino acid residue where a different missense change determined to be pathogenic has been seen before",
        category: "computational",
        diseaseDependent: true,
        tabIndex: 2,
        subTabIndex: 0
    },
    {   code:"PM6",
        class: "pathogenic-moderate",
        definition: "De novo (without paternity & maternity confirmed)",
        definitionLong: "Assumed <i>de novo</i>, but without confirmation of maternity/paternity",
        category: "segregation",
        diseaseDependent: false,
        tabIndex: 4
    },
    {   code: "PS1",
        class: "pathogenic-strong",
        definition: "Same amino acid change as an established pathogenic variant",
        definitionLong: "Same amino acid change as a previously established pathogenic variant regardless of nucleotide change (has caveat)",
        category: "computational",
        diseaseDependent: true,
        tabIndex: 2,
        subTabIndex: 0
    },
    {   code: "PS2",
        class: "pathogenic-strong",
        definition: "De novo (paternity and maternity confirmed)",
        definitionLong: "<i>De novo</i> (both maternity and paternity confirmed) in a patient with the disease and no family history",
        category: "segregation",
        diseaseDependent: true,
        tabIndex: 4
    },
    {   code: "PS3",
        class: "pathogenic-strong",
        definition: "Well-established functional studies show a deleterious effect",
        definitionLong: "Well established <i>in vitro</i> or <i>in vivo</i> functional studies supportive of a damaging effect on the gene or gene product",
        category: "functional",
        diseaseDependent: true,
        tabIndex: 3
    },
    {   code: "PS4",
        class: "pathogenic-strong",
        definition: "Prevalence in affecteds statistically increased over controls",
        definitionLong: "The prevalence of the variant in affected individuals is significantly increased compared with the prevalence in controls",
        category: "segregation",
        diseaseDependent: true,
        tabIndex: 4
    },
    {   code: "PVS1",
        class: "pathogenic-very-strong",
        definition: "Predicted null variant in a gene where LOF is a known mechanism of disease",
        definitionLong: "Null variant (nonsense, frameshift, canonical +/- 1 or 2 splice sites, initiation codon, single or multiexon deletion) in a gene where LOF is a known mechanism of disease (has caveats)",
        category: "computational",
        diseaseDependent: true,
        tabIndex: 2,
        subTabIndex: 1
    }];

export const CODE_STRIP_LOOKUP_TABLE = codeStripValues.reduce((acc, cur) => {
    return {
        ...acc,
        [cur.code]: cur
    }
}, {})
