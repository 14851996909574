import React from 'react';
import PmidSummary from "../components/common/article/PmidSummary";
import ClinVarSummary from "../components/common/article/ClinVarSummary";

/**
 * Method to display the source summary given the annotation
 * If annotation is article, display PMID summary.
 * If annotation is ClinVar record, display SCV summary.
 * @param {object} annotation - annotation
 */
export function renderAnnotationSourceSummary(
    annotation,
    displayJournal,
    linkout,
    linkoutOnNewLine,
    showComment
){
    return (
        <>
            {annotation?.article && 
                <PmidSummary
                    article={annotation.article}
                    displayJournal={displayJournal}
                    pmidLinkout={linkout}
                    pmidLinkoutOnNewLine={linkoutOnNewLine}
                />
            }
            {annotation?.scv &&
                <ClinVarSummary
                    scv={annotation.scv}
                    linkout={linkout}
                    showComment={showComment}
                />
            }
        </>
    );
}
